<template>
  <div class="anthorize-bg">
    <el-dialog
      class="anthorize"
      :title="$t('Authorize.title')"
      :visible.sync="dialogVisible"
      center
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
    >
      <form :action="token" method="post" class="form">
        <span slot="footer" class="dialog-footer">
          <span>{{ $t('Authorize.text') }}</span>
          <input type="text" :value="client_id" name="client_id" hidden />
          <input type="text" :value="redirect_uri" name="redirect_uri" hidden />
          <input
            type="text"
            :value="response_type"
            name="response_type"
            hidden
          />
          <input type="text" :value="grant_type" name="grant_type" hidden />
          <input type="text" :value="state" name="state" hidden />
          <div class="list-btn">
            <el-button
              @click=";(dialogVisible = false), (noDialogVisible = true)"
              type="danger"
              >{{ $t('Authorize.no') }}</el-button
            >
            <el-button native-type="submit" type="success">{{ $t('Authorize.yes') }}</el-button>
          </div>
        </span>
      </form>
    </el-dialog>
    <el-dialog
      class="anthorize"
      title="提示"
      :visible.sync="noDialogVisible"
      center
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
    >
      <span>你沒有權限</span>
    </el-dialog>
    <!-- <p class="anthorize" v-if="noDialogVisible">你沒有權限</p> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: true,
      noDialogVisible: false,
      client_id: this.$route.query.client_id || '',
      redirect_uri: this.$route.query.redirect_uri || '',
      response_type: this.$route.query.response_type || '',
      grant_type: this.$route.query.grant_type || '',
      state: this.$route.query.state,
      token:
        process.env.VUE_APP_BASE_API +
        'oauth/authorize?token=' +
        window.sessionStorage.getItem('token'),
    }
  },
  mounted() {
    let locale = window.localStorage.locale
    if(locale=='en') {
      this.$i18n.locale = 'en'
    }
    else {
      this.$i18n.locale = 'cn'
    }
  }
}
</script>
<style lang="scss">
button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.anthorize-bg {
  // min-height: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .anthorize {
    margin: 8% auto;
    width: 50%;
    // width: 100%;
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    .form {
      text-align: center;
      .list-btn {
        margin-top: 50px;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .el-dialog {
    width: 100% !important;
  }
}
</style>
